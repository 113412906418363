import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import NewCustomer from "containers/NewCustomer";
import PartnerWrapper from "../partner.style";
import Humberto from "common/assets/image/partners/american-profit-consulting/humberto.png";
import IWALogo from "common/assets/image/partners/iwa/iwa.png";

const CustomerIntakePage = ({ location }) => {
    return (
        <Layout location={location.pathname}>
            <Seo title="American Profit Consulting: New Business Intake Form" />
            <PartnerWrapper>
                <div className="Partner-info">
                    <div className="contact-info">
                        <h3>Humberto Hernandez, Jr</h3>
                        <p className="license">President<br/>
                        619-200-0351 <br/>
                        hhj@americanprofitconsulting.com<br/>
                        </p> 
                        <a href="https://americanprofitconsulting.com/" target="iwa">American Profit Consulting</a>
                        
                    </div>
                    <div className="profile-image">
                        <img src={Humberto} />
                    </div>
                </div>
            </PartnerWrapper>
            <NewCustomer referrer="recWzycuKVRjQXxuV"/>
        </Layout>
    );
};

export default CustomerIntakePage;